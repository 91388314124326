import { useAxios } from '@/app/composable';
import { Ref, computed, ref } from '@vue/composition-api';
import { WorkflowAPI } from '../api';
import { TriggerCondition, Workflow, WorkflowTrigger } from '../types';
import store from '@/app/store';
import {
    TriggerConditionType,
    TriggerEntityConditionType,
    TriggerEntityType,
    TriggerPipelineStatus,
    TRIGGER_OPERATORS_MAP,
} from '../constants/trigger.constants';
import { clone, isNil } from 'ramda';
import { Asset } from '@/modules/asset/types';

export function useWorkflowTriggers(workflow: Ref<Workflow>, initialize = true) {
    const { exec, loading: saving } = useAxios(true);
    const loading = computed(() => store.getters.pipelineDesigner.isLoadingTriggers);

    const triggers = computed((): readonly WorkflowTrigger[] => store.getters.pipelineDesigner.triggers || []);

    const hasAccessToTriggers = computed(() =>
        triggers.value.flatMap((trigger) => trigger.conditions).every((condition) => condition.hasAccess),
    );

    const hasInvalidInputAssetInTriggers = computed(() =>
        triggers.value.flatMap((trigger) => trigger.conditions).some((condition) => condition.hasInvalidInputAsset),
    );

    const getTriggerSummary = (conditions: TriggerCondition[], assets: Asset[] = [], pipelines: Workflow[] = []) => {
        if (!conditions.length) return [];
        const summary: any[] = [];
        const clonedConditions = clone(conditions);

        for (const condition of clonedConditions) {
            if (condition.type === TriggerConditionType.DataLoaded && condition.entityTypeId) {
                const conditionSummary: {
                    condition: string;
                    checks?: string[];
                    evaluation?: string;
                } = {
                    condition: '',
                };

                if (!condition.entityName) {
                    condition.entityName =
                        assets?.find((asset: Asset) => asset.id === Number(condition.entityId))?.name || '';
                }

                const entityType = condition.entityTypeId === TriggerEntityConditionType.Dataset ? 'dataset' : 'result';

                conditionSummary.condition = `when new data arrive in input ${entityType} "${condition.entityName}"`;
                if (condition.checks?.filters?.length) {
                    conditionSummary.checks = [];

                    condition.checks.filters.forEach((check: any) => {
                        if (check.field && check.operator && !isNil(check.value)) {
                            conditionSummary.checks?.push(
                                `the "${check.field}" field is ${TRIGGER_OPERATORS_MAP[
                                    check.operator
                                ].toLowerCase()} "${check.value}"`,
                            );
                        }
                    });
                }

                if (condition.checks?.evaluation) {
                    conditionSummary.evaluation = `evaluated on the ${condition.checks.evaluation.toLowerCase()} row`;
                }

                summary.push(conditionSummary);
            } else if (
                condition.type === TriggerConditionType.PipelineExecuted &&
                condition.entityType &&
                condition.checks?.status
            ) {
                const conditionSummary: { condition: string; status: string } = {
                    condition: '',
                    status: '',
                };

                if (!condition.entityName && condition.entityType === TriggerEntityType.Workflow) {
                    condition.entityName =
                        pipelines?.find((pipeline: Workflow) => pipeline.id === condition.entityId)?.name || '';
                }

                conditionSummary.condition =
                    condition.entityType === TriggerEntityType.Workflow &&
                    condition.entityTypeId === TriggerEntityConditionType.DataCheckin
                        ? 'when data check-in pipeline'
                        : 'when data analytics pipeline';

                conditionSummary.condition = `${conditionSummary.condition} "${condition.entityName}"`;

                if (condition.checks.status === TriggerPipelineStatus.Success) {
                    conditionSummary.status = 'was successfully executed';
                } else if (condition.checks.status === TriggerPipelineStatus.Failure) {
                    conditionSummary.status = 'was executed with failure';
                }

                summary.push(conditionSummary);
            }
        }

        return summary;
    };

    const save = async (trigger: any) => {
        return new Promise<string>((resolve, reject) => {
            if (trigger.id) {
                exec(WorkflowAPI.updateTrigger(workflow.value.id, trigger.id, trigger))
                    .then(() => {
                        refetch();
                        resolve('Trigger has been updated successfully');
                    })
                    .catch((e) => reject(e.response.data.message));
            } else {
                exec(WorkflowAPI.createTrigger(workflow.value.id, trigger))
                    .then(() => {
                        refetch();
                        resolve('Trigger has been created successfully');
                    })
                    .catch((e) => reject(e.response.data.message));
            }
        });
    };

    const deleteTrigger = (id: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            exec(WorkflowAPI.deleteTrigger(workflow.value.id, id))
                .then(async () => {
                    await refetch();
                    resolve();
                })
                .catch((e) => {
                    reject(e.response.data.message);
                });
        });
    };

    const changeStatus = (trigger: WorkflowTrigger): Promise<void> => {
        const idx = triggers.value.findIndex((obj: WorkflowTrigger) => obj.id === trigger.id);
        return new Promise((resolve, reject) => {
            exec(WorkflowAPI.toggleTrigger(workflow.value.id, trigger.id))
                .then((res: any) => (triggers.value[idx].isEnabled = res.data.isEnabled))
                .catch((e) => {
                    reject(e.response.data.message);
                });
        });
    };

    const refetch = async (force = true) => {
        await store.dispatch.pipelineDesigner.loadTriggers(force);
    };

    if (initialize) refetch(false);

    return {
        triggers,
        saving,
        loading,
        hasAccessToTriggers,
        hasInvalidInputAssetInTriggers,
        save,
        deleteTrigger,
        changeStatus,
        refetch,
        getTriggerSummary,
    };
}
